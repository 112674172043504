<template>
  <div style="position: relative">
    <div class="menu">
      <div
        class="item pingshenmenu"
        :class="topClass == 0 ? 'bottom-border' : ''"
        @click="changeMode('receiving')"
      >
        {{ $t("addMyColla.myReceive") }}
      </div>
      <div
        class="item pingshenmenu"
        :class="topClass == 1 ? 'bottom-border' : ''"
        @click="changeMode('sending')"
      >
        {{ $t("addMyColla.mySend") }}
      </div>
      <div
        v-if="!limits_of_authority_disable('REVIEW_VIEW_ALL')"
        class="item pingshenmenu"
        :class="topClass == 2 ? 'bottom-border' : ''"
        @click="changeMode('reviewing')"
      >
        {{ $t("addMyColla.allReviewRequest") }}
      </div>
      <i
        class="iconfont"
        :class="is_show_chart ? 'icon-chart_on' : 'icon-chart hover-icon'"
        style="
          position: relative;
          right: 0.082rem;
          top: 6px;
          color: rgb(48, 100, 143);
          font-size: 16px;
          cursor: pointer;
          height: fit-content;
        "
        @click="show_chart"
      >
      </i>
    </div>
    <!-- 我收到的评审 -->
    <el-table
      v-show="(mode === 'receiving') & !is_show_chart"
      height="80vh"
      :data="receivingData"
      style="width: 100%; background: white"
      :header-cell-style="{
        color: '#383838',
        'font-weight': '700',
      }"
      :cell-style="changeCellStyle"
    >
      <el-table-column
        prop="title"
        :label="$t('addMyColla.table.title1')"
        :min-width="480"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          <span style="cursor: pointer" @click="openReviewMindmap(scope.row)">{{
            scope.row.title
          }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="reviewProcessStatus"
        :label="$t('addMyColla.table.title2')"
        :min-width="240"
        :show-overflow-tooltip="true"
      >
        <template slot-scope="scope">
          <span
            class="pingshen-status-tag"
            :style="{
              borderStyle: 'solid',
              color: getReceivingTheme(scope.row.isHandle),
            }"
          >
            {{ getReceivingStatusText(scope.row.isHandle) }}
          </span>
        </template>
      </el-table-column>
      <el-table-column
        prop="owner"
        :label="$t('addMyColla.table.title3')"
        :min-width="280"
      >
        <template slot-scope="scope">
          <span style="display: flex; align-items: center; gap: 10px">
            <personal-avatar
              :placement="'right'"
              :size="33"
              :avatar="
                (getUser(scope.row.createdBy) &&
                  getUser(scope.row.createdBy).avatar) ||
                ''
              "
              :nickname="
                (getUser(scope.row.createdBy) &&
                  getUser(scope.row.createdBy).nickname) ||
                ''
              "
              :colorid="scope.row.createdBy"
              style="justify-content: left"
            ></personal-avatar>
            <span>{{ getUser(scope.row.createdBy).nickname }}</span>
          </span>
        </template>
      </el-table-column>
      <el-table-column
        prop="endDateTime"
        :label="$t('addMyColla.table.title4')"
        :min-width="200"
      >
        <!-- <template #header>
          <div class="sortable-column-header">
            {{ $t("addMyColla.table.title4") }}
            <div class="caret-wrapper">
              <i
                :class="{
                  'el-icon-caret-top': true,
                  'el-icon-caret-colored': true,
                }"
              ></i>
              <i
                :class="{
                  'el-icon-caret-bottom': true,
                  'el-icon-caret-colored': true,
                }"
                style="margin-top: -5px"
              ></i>
            </div>
          </div>
        </template> -->
      </el-table-column>
      <el-table-column :label="$t('addMyColla.table.title7')" :min-width="60">
        <template slot-scope="scope">
          <el-dropdown v-if="!scope.row.isHandle">
            <span class="el-dropdown-link" style="color: #84aace">
              <!-- <i class="el-icon-arrow-down el-icon--right"></i> -->
              &nbsp;&nbsp;
              <i
                class="iconfont icon-a-216gengduo-shuxiang"
                style="color: rgb(48, 100, 143)"
              />
              &nbsp;&nbsp;
            </span>
            <el-dropdown-menu slot="dropdown">
              <div>
                <div
                  class="operate_button"
                  @click="changeReviewer(scope.row, true)"
                >
                  <i style="margin-right: 20px">
                    <i class="iconfont icon-edit" />
                  </i>
                  <span>{{ $t("addMyColla.table.title15") }}</span>
                </div>
              </div>
            </el-dropdown-menu>
          </el-dropdown>
        </template>
      </el-table-column>
    </el-table>
    <!-- 我发出的邀请 -->
    <el-table
      v-show="(mode === 'sending') & !is_show_chart"
      :data="sendingData"
      height="80vh"
      style="width: 100%; background: white"
      :header-cell-style="{
        color: '#383838',
        'font-weight': '700',
      }"
      :cell-style="changeCellStyle"
    >
      <el-table-column
        prop="title"
        :label="$t('addMyColla.table.title1')"
        :min-width="350"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          <span style="cursor: pointer" @click="openInReviewMindmap(scope.row)">
            {{ scope.row.title }}
          </span>
        </template>
      </el-table-column>
      <el-table-column
        prop="status"
        :label="$t('addMyColla.table.title2')"
        :min-width="200"
        :show-overflow-tooltip="true"
      >
        <template slot-scope="scope">
          <span
            class="pingshen-status-tag"
            :style="{
              borderStyle: 'solid',
              borderWidth: '1px',
              color: getSendingTheme(scope.row.reviewProcessStatus),
            }"
          >
            {{ getSendingStatusText(scope.row.reviewProcessStatus) }}
          </span>
        </template>
      </el-table-column>
      <el-table-column
        prop="owner"
        :label="$t('addMyColla.table.title5')"
        :min-width="250"
        align="center"
      >
        <template slot-scope="scope">
          <el-tooltip
            :disabled="scope.row.passPerson === scope.row.allPerson"
            effect="dark"
            placement="top"
          >
            <div slot="content">
              <span>{{
                $t("addMyColla.table.title14") + scope.row.unfinishedPerson
              }}</span>
            </div>
            <span>
              <span style="color: green">{{ scope.row.passPerson }}</span>
              <span style="margin: 0 4px">/</span>
              <span>{{ scope.row.allPerson }}</span>
            </span>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column
        prop="create_time"
        :label="$t('addMyColla.table.title4')"
        :min-width="200"
      >
        <!-- <template #header>
          <div class="sortable-column-header">
            {{ $t("addMyColla.table.title4") }}
            <div class="caret-wrapper">
              <i
                :class="{
                  'el-icon-caret-top': true,
                  'el-icon-caret-colored': true,
                }"
              ></i>
              <i
                :class="{
                  'el-icon-caret-bottom': true,
                  'el-icon-caret-colored': true,
                }"
                style="margin-top: -5px"
              ></i>
            </div>
          </div>
        </template> -->
        <template slot-scope="scope">
          {{ scope.row.endDateTime }}
        </template>
      </el-table-column>
      <el-table-column :label="$t('addMyColla.table.title7')" :min-width="60">
        <template slot-scope="scope">
          <el-dropdown>
            <span class="el-dropdown-link" style="color: #84aace">
              <!-- <i class="el-icon-arrow-down el-icon--right"></i> -->
              &nbsp;&nbsp;
              <i
                class="iconfont icon-a-216gengduo-shuxiang"
                style="color: rgb(48, 100, 143)"
              />
              &nbsp;&nbsp;
            </span>
            <el-dropdown-menu slot="dropdown">
              <div v-if="scope.row.reviewProcessStatus !== 'END'">
                <div
                  class="operate_button"
                  @click="changeReviewer(scope.row, false)"
                >
                  <i style="margin-right: 20px">
                    <i class="iconfont icon-edit" />
                  </i>
                  <span>{{ $t("addMyColla.table.title15") }}</span>
                </div>
              </div>
              <div v-if="scope.row.reviewProcessStatus !== 'END'">
                <div class="operate_button" @click="tipReview(scope.row)">
                  <i style="margin-right: 20px">
                    <i class="iconfont icon-fasong"> </i
                  ></i>
                  <span>{{ $t("addMyColla.table.title8") }}</span>
                </div>
              </div>
              <div>
                <div
                  class="operate_button"
                  v-if="scope.row.reviewProcessStatus !== 'END'"
                  @click="endReviewTaskAhead(scope.row)"
                >
                  <i style="margin-right: 20px">
                    <i class="iconfont icon-stop" />
                  </i>
                  <span>{{ $t("addMyColla.table.title9") }}</span>
                </div>
              </div>
              <div>
                <div class="operate_button" @click="deleteTask(scope.row)">
                  <i style="margin-right: 20px">
                    <i class="iconfont icon-delete-red" />
                  </i>
                  <span>{{ $t("addMyColla.table.title10") }}</span>
                </div>
              </div>
            </el-dropdown-menu>
          </el-dropdown>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      v-if="(mode === 'sending') & !is_show_chart"
      style="margin-top: 10px"
      :current-page="sendingPageInfo.currentPage + 1"
      background
      :page-sizes="[10, 20, 50]"
      :page-size="sendingPageInfo.pageSize"
      layout="total, prev, pager, next, jumper"
      :total="sendingPageInfo.total"
      @current-change="changeSendingPage"
    >
    </el-pagination>
    <!-- 全部评审请求 -->
    <el-table
      v-show="mode === 'reviewing'"
      height="80vh"
      :data="reviewingData"
      style="width: 100%; background: white"
      :header-cell-style="{
        color: '#383838',
        'font-weight': '700',
      }"
      :cell-style="changeCellStyle"
    >
      <el-table-column
        prop="title"
        :label="$t('addMyColla.table.title1')"
        :min-width="480"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          <span style="cursor: pointer" @click="openReviewMindmap(scope.row)">{{
            scope.row.title
          }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="reviewProcessStatus"
        :label="$t('addMyColla.table.title2')"
        :min-width="240"
        :show-overflow-tooltip="true"
      >
        <template slot-scope="scope">
          <span
            class="pingshen-status-tag"
            :style="{
              borderStyle: 'solid',
              color: getReviewingTheme(scope.row.reviewProcessStatus),
            }"
          >
            {{ getReviewingStatusText(scope.row.reviewProcessStatus) }}
          </span>
        </template>
      </el-table-column>
      <el-table-column
        prop="owner"
        :label="$t('addMyColla.table.title3')"
        :min-width="200"
      >
        <template slot-scope="scope">
          <span style="display: flex; align-items: center; gap: 10px">
            <personal-avatar
              :placement="'right'"
              :size="33"
              :avatar="
                (getUser(scope.row.createdBy) &&
                  getUser(scope.row.createdBy).avatar) ||
                ''
              "
              :nickname="
                (getUser(scope.row.createdBy) &&
                  getUser(scope.row.createdBy).nickname) ||
                ''
              "
              :colorid="scope.row.createdBy"
              style="justify-content: left"
            ></personal-avatar>
            <span>{{ getUser(scope.row.createdBy).nickname }}</span>
          </span>
        </template>
      </el-table-column>
      <el-table-column
        prop="owner"
        :label="$t('addMyColla.table.title5')"
        :min-width="250"
        align="center"
      >
        <template slot-scope="scope">
          <el-tooltip
            :disabled="scope.row.passPerson === scope.row.allPerson"
            effect="dark"
            placement="top"
          >
            <div slot="content">
              <span>{{
                $t("addMyColla.table.title6") + scope.row.unfinishedPerson
              }}</span>
            </div>
            <span>
              <span style="color: green">{{ scope.row.passPerson }}</span>
              <span style="margin: 0 4px">/</span>
              <span>{{ scope.row.allPerson }}</span>
            </span>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column
        prop="endDateTime"
        :label="$t('addMyColla.table.title4')"
        :min-width="200"
      >
        <!-- <template #header>
          <div class="sortable-column-header">
            {{ $t("addMyColla.table.title4") }}
            <div class="caret-wrapper">
              <i
                :class="{
                  'el-icon-caret-top': true,
                  'el-icon-caret-colored': true,
                }"
              ></i>
              <i
                :class="{
                  'el-icon-caret-bottom': true,
                  'el-icon-caret-colored': true,
                }"
                style="margin-top: -5px"
              ></i>
            </div>
          </div>
        </template> -->
      </el-table-column>
    </el-table>
    <el-pagination
      v-if="mode === 'reviewing'"
      style="margin-top: 10px"
      :current-page="reviewingPageInfo.currentPage + 1"
      background
      :page-sizes="[10, 20, 50]"
      :page-size="reviewingPageInfo.pageSize"
      layout="total, prev, pager, next, jumper"
      :total="reviewingPageInfo.total"
      @current-change="changeReviewingPage"
    >
    </el-pagination>
    <el-dropdown
      v-show="is_show_chart"
      :hide-on-click="false"
      trigger="hover"
      width="240"
      height="288"
      style="position: absolute; right: 0; top: 6%"
    >
      <div class="btn">
        {{ $t("addMyColla.table.title11")
        }}<i class="el-icon-arrow-down el-icon--right"></i>
      </div>
      <el-dropdown-menu slot="dropdown">
        <el-checkbox
          v-model="checkAll_fileType"
          class="checkbox"
          :indeterminate="isIndeterminate"
          @change="handleCheckAllChange"
        >
          {{ $t("addMyColla.table.title12") }}
        </el-checkbox>
        <el-checkbox-group
          v-model="checked_fileType"
          class="select"
          @change="handleCheckedCitiesChange"
        >
          <el-checkbox
            v-for="(item, index) in fileTypesAll"
            :key="index"
            :label="item.fileTypeId"
            class="checkbox"
          >
            {{ item.name }}
          </el-checkbox>
        </el-checkbox-group>
      </el-dropdown-menu>
    </el-dropdown>
    <!-- 图表 -->
    <div
      v-show="is_show_chart"
      id="echarts_main_wrap"
      ref="echarts_main_wrap"
      class="echarts_main_wrap"
    >
      <div class="bar1">
        <div id="bar1" ref="bar1"></div>
      </div>
      <div class="bar1">
        <div id="bar2" ref="bar2"></div>
      </div>
    </div>
    <!-- 对话框 节点列表 -->
    <el-dialog
      :title="pieTitle"
      :visible.sync="dialogTableVisible"
      :close-on-click-modal="false"
      :show-close="true"
    >
      <el-table
        :data="gridData"
        style="width: 100%; background: white"
        :header-cell-style="{
          color: '#383838',
          'font-weight': '700',
        }"
        :cell-style="changeCellStyle"
        width="800"
      >
        <el-table-column
          property="nodeKey"
          style="color: rgb(255, 195, 0); font-weight: 700"
          label="key"
          width="200"
        >
          <template slot-scope="scope">
            <div style="display: inline-block">
              <span
                style="
                  color: rgb(255, 195, 0);
                  font-weight: 700;
                  cursor: pointer;
                "
                @click="rowClicked(scope.row)"
                >{{ scope.row.nodeKey }}</span
              >
            </div>
            <div style="display: inline-block">
              <div style="display: inline-block">
                <i
                  style="
                    font-size: 12px;
                    margin-left: 16px;
                    cursor: pointer;
                    color: rgb(48, 100, 143);
                  "
                  class="iconfont icon-node_link"
                  @click="openInReviewMindmap(scope.row)"
                ></i>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          property="topic"
          :label="$t('addMyColla.chart.title1')"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          property="assignee"
          :label="$t('addMyColla.chart.title2')"
          width="200"
          show-overflow-tooltip
        ></el-table-column>
      </el-table>
      <el-pagination
        style="margin: 30px 0 25px 0"
        :current-page="nodeReviewPage.page + 1"
        background
        :page-sizes="[10, 20, 50]"
        :page-size="nodeReviewPage.page_size"
        layout="total, prev, pager, next, jumper"
        :total="nodeReviewPage_total"
        @current-change="changeNodeReview"
      >
      </el-pagination>
    </el-dialog>
    <el-dialog
      :visible.sync="changeReviewerDialog"
      width="25%"
      center
      :title="$t('addMyColla.table.title15')"
      :append-to-body="true"
      :close-on-click-modal="false"
      :show-close="true"
    >
      <div>
        <el-select
          v-model="changeReviewerIds"
          popper-class="user_select"
          :loading-text="$t('loading.loading2')"
          filterable
          clearable
          :multiple="!isTransfer"
          style="width: 100%"
          :filter-method="filterMethod"
        >
          <el-option
            v-for="opt in filterUserList"
            v-show="!opt.deleted && !opt.hide"
            :key="opt.accountId"
            :label="opt.nickname"
            :value="opt.accountId"
          >
            <personal-avatar
              class="avatar"
              :avatar="opt.avatar"
              :colorid="opt.accountId"
              :nickname="opt.nickname"
              :size="20"
            >
            </personal-avatar>
            <span class="select_item">{{ opt.nickname }}</span>
          </el-option>
        </el-select>
      </div>
      <div slot="footer" style="text-align: right">
        <el-button
          type="info"
          @click="
            changeReviewerDialog = false;
            changeReviewerIds = [];
          "
          >{{ $t("btn.cancelBtn") }}
        </el-button>
        <el-button
          type="primary"
          size="small"
          class="button-confirm"
          @click="changeReviewerConfirm"
          >{{ $t("btn.confirmBtn") }}
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import PersonalAvatar from "@/components/personal";
import {
  getReceivingReviewList,
  getSendingReviewList,
  getReviewingReviewList,
  getnodeReview,
  getNodeReviewTime,
  getNodeReviewThrough,
  endReviewTask,
  tipReviewTask,
  deleteReviewTask,
  changeReviewers,
  transferReviewers,
} from "@/network/collaboration/index.js";
import { mapGetters } from "vuex";
import { Message } from "element-ui";
import { get_file } from "@/network/baseline/index";
import { limits_of_authority_disable } from "@/utils/limits";

import { pinyin } from "pinyin-pro";
export default {
  name: "HomeMyCollaborationVue",
  components: {
    PersonalAvatar,
  },
  data() {
    return {
      activeIndex: "/home/my_collaboration",
      receivingData: [],
      sendingData: [],
      reviewingData: [],
      mode: "receiving",
      sendingPageInfo: {
        // 我发出的邀请 分页
        currentPage: 0,
        pageSize: 20,
        total: 0,
      },
      reviewingPageInfo: {
        // 全部评审请求 分页
        currentPage: 0,
        pageSize: 20,
        total: 0,
      },
      chartingPageInfo: {
        // 评审通过节点 分页
        currentPage: 0,
        pageSize: 20,
        total: 0,
      },
      isReviewing: false, //控制全部评审请求中右侧小图标是否显示
      is_show_chart: false, //控制 全部评审请求 页面中，视图是否显示
      myChart_bar1: "", //用于echarts 视图暂存变量 bar1
      myChart_bar2: "", //用于echarts 视图暂存变量 bar2
      checkAll_fileType: true, // 筛选按钮 全选
      checked_fileType: [], // 筛选按钮 已选取内容
      fileTypesAll: [], // 筛选按钮 筛选数据缓存
      isIndeterminate: false, // 筛选按钮
      dialogTableVisible: false, // 弹出框节点列表
      gridData: [], // 弹出框 列表数据
      topClass: 0,
      approved: [], // 饼状图数据存储
      pieChart_data: [],
      nodeReviewPage: {
        // 饼状图评审通过节点 分页
        page: 0,
        page_size: 10,
        review_type: 0,
      },
      nodeReviewPage_total: 0, // 饼状图评审通过节点 分页总数
      pieTitle: "", // 饼状图列表标题
      bodyData: {
        filter: {
          queryConditions: [
            {
              isCustom: false,
              name: "fileTypeId",
              value: [],
            },
          ],
        },
      },
      barData: [], // 饼状图 data 数据
      changeReviewerDialog: false,
      changeReviewerIds: [],
      filterUserList: [],
      tempChangeReviewerData: {},
      isTransfer: false,
    };
  },
  computed: {
    ...mapGetters(["user_list", "fileType"]),
    all_user_list() {
      return Object.values(this.user_list);
    },
  },
  created() {
    this.initCheckBox();
    this.getReceivingData();
    this.getSendingData();
    !this.limits_of_authority_disable("REVIEW_VIEW_ALL")
      ? this.getReviewingData()
      : "";
    this.getnodeReviewInfo();
    this.singleUserVisibleChange();
  },
  beforeDestroy() {},
  methods: {
    limits_of_authority_disable,
    // 过滤方法
    getPinyinMatch(inputValue, list, label) {
      /* 输入内容拼音转换 */
      // 完整拼音
      const pyInput = pinyin(inputValue, {
        toneType: "none",
        type: "array",
      }).join("");
      // 拼音首字母
      const headerPyInput = pinyin(inputValue, {
        pattern: "first",
        type: "array",
      }).join("");
      return list.filter((e) => {
        const pyE = pinyin(e[label ? label : "nickname"], {
          toneType: "none",
          type: "array",
        })
          .join("")
          .slice(0, pyInput.length);
        // 列表中每项的拼音首字母
        const headerPyE = pinyin(e[label ? label : "nickname"], {
          pattern: "first",
          type: "array",
        })
          .join("")
          .slice(0, headerPyInput.length);
        // 过滤匹配
        return (
          e[label ? label : "nickname"].includes(inputValue) || pyE === pyInput
          // 是否提取首字母匹配
          // ||
          // headerPyE === headerPyInput
        );
      });
    },
    filterMethod(val) {
      this.filterUserList = this.getPinyinMatch(val, this.all_user_list);
    },
    singleUserVisibleChange() {
      this.filterUserList = JSON.parse(JSON.stringify(this.all_user_list));
    },
    // 修改评审人
    changeReviewerConfirm() {
      if (this.isTransfer) {
        const params = {
          projectId: this.get_pid(),
          reviewProcessId: this.tempChangeReviewerData.reviewProcessId,
          targetAccountId: this.changeReviewerIds,
        };
        transferReviewers(params).then((res) => {
          this.changeReviewerIds = [];
          this.changeReviewerDialog = false;
          this.getReceivingData();
          this.getSendingData();
          !this.limits_of_authority_disable("REVIEW_VIEW_ALL")
            ? this.getReviewingData()
            : "";
        });
      } else {
        const params = {
          projectId: this.get_pid(),
          reviewProcessId: this.tempChangeReviewerData.reviewProcessId,
          data: this.changeReviewerIds,
        };
        changeReviewers(params).then((res) => {
          this.changeReviewerIds = [];
          this.changeReviewerDialog = false;
          this.getReceivingData();
          this.getSendingData();
          !this.limits_of_authority_disable("REVIEW_VIEW_ALL")
            ? this.getReviewingData()
            : "";
        });
      }
    },
    changeReviewer(row, isTransfer) {
      if (!isTransfer) {
        const arr = row.reviewerApprovedInfos
          .map((item) => {
            if (!item.finished) {
              return item.reviewerId;
            }
          })
          .filter((item) => {
            return item;
          });
        this.changeReviewerIds = arr;
        console.log(arr);
      } else {
        this.changeReviewerIds = "";
      }
      this.isTransfer = isTransfer;
      this.tempChangeReviewerData = row;
      this.changeReviewerDialog = true;
    },
    //节点点击事件
    rowClicked(row) {
      const { href } = this.$router.resolve({
        path: `/${this.get_pid()}/nodes/key/${row.nodeKey}`,
      });
      window.open(href, "_blank");
    },
    initCheckBox() {
      this.fileTypesAll = Object.values(this.fileType);
      this.checked_fileType = Object.values(this.fileType).map((item) => {
        return item.fileTypeId;
      });
      this.bodyData.filter.queryConditions[0].value = Object.values(
        this.fileType
      ).map((item) => {
        return item.fileTypeId;
      });
    },
    // 筛选按钮 全选功能
    handleCheckAllChange(val) {
      if (val) {
        this.checked_fileType = Object.values(this.fileType).map((item) => {
          return item.fileTypeId;
        });
        this.bodyData.filter.queryConditions[0].value = Object.values(
          this.fileType
        ).map((item) => {
          return item.fileTypeId;
        });
      } else {
        this.checked_fileType = [];
        this.bodyData.filter.queryConditions[0].value = [];
      }
      this.isIndeterminate = false;
      this.getnodeReviewInfo();
      this.getNodeReviewTimes();
    },
    // 筛选按钮 点击单个点击选取
    handleCheckedCitiesChange(values) {
      let checkedCount = values.length;
      this.checkAll_fileType = checkedCount === this.fileTypesAll.length;
      this.isIndeterminate =
        checkedCount > 0 && checkedCount < this.fileTypesAll.length;
      this.checked_fileType = values;
      this.bodyData.filter.queryConditions[0].value = values;
      this.getnodeReviewInfo();
      this.getNodeReviewTimes();
    },
    openInReviewMindmap(item) {
      get_file(this.get_pid(), item.fileId || item.fileUuid).then((result) => {
        //跳转到思维导图
        const { href } = this.$router.resolve({
          path: `/${this.get_pid()}/myMind/${result.key}`,
          query: item.fileId ? { in_review: "in_review" } : {},
        });
        window.open(href, "_blank");
      });
    },
    openReviewMindmap(item) {
      get_file(this.get_pid(), item.fileId).then((result) => {
        //跳转到思维导图
        const { href } = this.$router.resolve({
          path: `/${this.get_pid()}/myMind/${result.key}`,
          query: { process_review: "process_review" },
        });
        window.open(href, "_blank");
      });
    },
    // 获取我发出的邀请 列表数据
    getSendingData() {
      getSendingReviewList(this.get_pid(), {
        pageNumber: this.sendingPageInfo.currentPage,
        pageSize: this.sendingPageInfo.pageSize,
      }).then((res) => {
        this.sendingData = res.content;
        this.sendingPageInfo.total = res.totalElements;
        this.sendingData.forEach((item) => {
          if (item.reviewerApprovedInfos.length === 0) {
            item.passPerson = 0;
          } else {
            let passPerson = 0;
            let unfinishedPerson = [];
            item.reviewerApprovedInfos.forEach((info) => {
              if (info.finished) {
                passPerson += 1;
              } else {
                unfinishedPerson.push(this.getUser(info.reviewerId).nickname);
              }
            });
            item.passPerson = passPerson;
            item.unfinishedPerson = unfinishedPerson.join(", ");
          }
          item.allPerson = item.reviewerApprovedInfos.length;
        });
      });
    },
    // 获取全部评审请求 列表数据
    getReviewingData() {
      getReviewingReviewList(this.get_pid(), {
        pageNumber: this.reviewingPageInfo.currentPage,
        pageSize: this.reviewingPageInfo.pageSize,
      }).then((res) => {
        this.reviewingData = res.content;
        this.reviewingPageInfo.total = res.totalElements;
        this.reviewingData.forEach((item) => {
          if (item.reviewerApprovedInfos.length === 0) {
            item.passPerson = 0;
          } else {
            let passPerson = 0;
            let unfinishedPerson = [];
            item.reviewerApprovedInfos.forEach((info) => {
              if (info.finished) {
                passPerson += 1;
              } else {
                unfinishedPerson.push(this.getUser(info.reviewerId).nickname);
              }
            });
            item.passPerson = passPerson;
            item.unfinishedPerson = unfinishedPerson.join(", ");
          }
          item.allPerson = item.reviewerApprovedInfos.length;
        });
      });
    },
    getReceivingData() {
      getReceivingReviewList(this.get_pid()).then((res) => {
        this.receivingData = res;
      });
    },
    changeSendingPage(pageNumber) {
      this.sendingPageInfo.currentPage = pageNumber - 1;
      this.getSendingData();
    },
    changeReviewingPage(pageNumber) {
      this.reviewingPageInfo.currentPage = pageNumber - 1;
      this.getReviewingData();
    },
    changeChartingPage(pageNumber) {
      this.reviewingPageInfo.currentPage = pageNumber - 1;
      this.getSendingData();
    },
    changeNodeReview(pageNumber) {
      this.nodeReviewPage.page = pageNumber - 1;
      this.getNodeReviewThroughs();
    },
    deleteTask(task) {
      this.$confirm(
        this.$t("addMyColla.message.title1"),
        this.$t("canvas.fileDropDown.deleteForm.title"),
        {
          confirmButtonText: this.$t("addMyColla.message.title2"),
          cancelButtonText: this.$t("addMyColla.table.title10"),
          cancelButtonClass: "button_danger",
          confirmButtonClass: "button_info",
          type: "warning",
        }
      )
        .then(() => {
          this.$message({
            type: "info",
            message: this.$t("addMyColla.message.title3"),
          });
        })
        .catch(() => {
          deleteReviewTask(this.get_pid(), task.reviewProcessId).then(() => {
            this.getSendingData();
          });
        });
    },
    tipReview(task) {
      tipReviewTask(this.get_pid(), task.reviewProcessId).then(() => {
        Message({
          showClose: true,
          message: this.$t("addMyColla.message.title4"),
          type: "success",
        });
      });
    },
    endReviewTaskAhead(task) {
      endReviewTask(this.get_pid(), task.reviewProcessId).then(() => {
        this.getSendingData();
        this.getReceivingData();
        this.$store.commit(
          "updatePingshenNumber",
          !this.$store.getters.pingshenNumberIsToUpdate
        );
        Message({
          showClose: true,
          message: this.$t("addMyColla.message.title5"),
          type: "success",
        });
      });
    },
    getUser(userId) {
      return this.matchUserInfo(userId);
    },
    getReceivingStatusText(isHandle) {
      return isHandle
        ? this.$t("addMyColla.status.st1")
        : this.$t("addMyColla.status.st2");
    },
    getReceivingTheme(isHandle) {
      return isHandle ? "rgba(67, 207, 124, 1)" : "rgba(42, 130, 228, 1)";
    },
    getSendingStatusText(status) {
      const dict = {
        REVIEWING: this.$t("addMyColla.status.st3"),
        END: this.$t("addMyColla.status.st4"),
      };
      return dict[status];
    },
    getSendingTheme(status) {
      const dict = {
        END: "rgba(166, 166, 166, 1)",
        REVIEWING: "rgba(67, 207, 124, 1)",
      };
      return dict[status];
    },
    getReviewingStatusText(status) {
      const dict = {
        REVIEWING: this.$t("addMyColla.status.st3"),
        END: this.$t("addMyColla.status.st4"),
      };
      return dict[status];
    },
    getReviewingTheme(status) {
      const dict = {
        END: "rgba(166, 166, 166, 1)",
        REVIEWING: "rgba(67, 207, 124, 1)",
      };
      return dict[status];
    },
    // 获取节点的历史记录 节点评审次数
    getnodeReviewInfo() {
      getnodeReview(this.get_pid(), this.bodyData).then((res) => {
        this.approved = res;
        const arr = [
          {
            value: this.approved.approved_eq1,
            name: this.$t("addMyColla.status.st5"),
            itemStyle: {
              color: "rgba(53, 136, 229, 1)",
            },
          },
          {
            value: this.approved.approved_eq2,
            name: this.$t("addMyColla.status.st6"),
            itemStyle: {
              color: "rgba(0, 186, 173, 1)",
            },
          },
          {
            value: this.approved.approved_ge3,
            name: this.$t("addMyColla.status.st7"),
            itemStyle: {
              color: "rgba(246, 192, 34, 1)",
            },
          },
          {
            value: this.approved.un_approved,
            name: this.$t("addMyColla.status.st8"),
            itemStyle: {
              color: "rgba(255, 122, 92, 1)",
            },
          },
          {
            value: this.approved.un_review,
            name: this.$t("addMyColla.status.st9"),
            itemStyle: {
              color: "rgba(109, 127, 160, 1)",
            },
          },
          {
            value: this.approved.reviewing,
            name: this.$t("addMyColla.status.st3"),
            itemStyle: {
              color: "rgba(255, 147, 38, 1)",
            },
          },
        ];
        this.pieChart_data = [];
        arr.forEach((item) => {
          if (item.value > 0) {
            this.pieChart_data.push(item);
          }
        });
        this.pie_charts();
        this.bar_charts();
      });
    },
    // 点击切换评审页面内容 我收到的邀请 我发出的邀请 全部评审请求
    changeMode(mode) {
      this.mode = mode;
      this.topClass = mode == "receiving" ? 0 : mode == "sending" ? 1 : 2;
      this.is_show_chart = false;
    },
    changeCellStyle(row) {
      if (row.column.label === "ID") {
        return "color: #FFC300;font-weight: 700;"; // 修改的样式
      } else {
        return "color: #636363;font-weight: 700;";
      }
    },
    // 控制数据试图显隐
    show_chart() {
      this.mode = "svgimg";
      this.topClass = 3;
      // 处在看板页面
      if (!this.is_show_chart) {
        this.$nextTick(() => {
          this.init_charts();
        });
      }
      this.is_show_chart = true;
      this.getNodeReviewTimes();
    },
    // 初始化图表
    init_charts() {
      this.pie_charts();
      this.bar_charts();
      let element = this.$refs["echarts_main_wrap"];
      this.observer = new ResizeObserver(() => {
        this.myChart_bar1.resize();
        this.myChart_bar2.resize();
      });
      this.observer.observe(element);
    },
    // 饼状图
    pie_charts() {
      this.myChart_bar1 = this.$echarts.init(document.getElementById("bar1"));
      let option = {
        tooltip: {
          formatter: "{b} <br/> <b>{c}</b> <br/><b>{d}%</b>",
        },
        legend: {
          type: "scroll",
          orient: "vertical",
          align: "left",
          right: "5%",
          top: "10%",
          width: "20%",
          height: "80%",
          textStyle: {
            width: 150,
            overflow: "breakAll",
            rich: {
              title: {
                align: "left",
              },
              value: {
                align: "right",
              },
            },
          },
          tooltip: {
            show: true,
          },
        },
        series: [
          {
            left: -200,
            type: "pie",
            minAngle: 5,
            data: this.pieChart_data,
            radius: [0, "50%"],
            label: {
              show: true,
              position: "outside",
              formatter: "{b}: {c}\n{d}%",
              width: 80,
              textStyle: {
                fontSize: 12,
                fontWeight: "normal",
              },
            },
            labelLine: {
              show: true,
            },
            itemStyle: {
              borderColor: "#fff",
              borderWidth: 2,
            },
          },
        ],
        title: {
          text: this.$t("addMyColla.chart.title3"),
          textStyle: {
            fontFamily: "Source Han Sans CN",
            color: "rgba(56, 56, 56, 1)",
          },
        },
      };
      this.myChart_bar1.setOption(option);
      // 用于设置图表 自适应 容器大小
      // 处理点击事件并且跳转到相应的百度搜索页面
      this.myChart_bar1.off("click");
      this.myChart_bar1.on("click", (params) => {
        let nameObject = {};
        nameObject[this.$t("addMyColla.status.st5")] = 29187;
        nameObject[this.$t("addMyColla.status.st6")] = 29188;
        nameObject[this.$t("addMyColla.status.st7")] = 29189;
        nameObject[this.$t("addMyColla.status.st8")] = 29186;
        nameObject[this.$t("addMyColla.status.st9")] = 29185;
        nameObject[this.$t("addMyColla.status.st3")] = 29190;
        this.nodeReviewPage.page = 0;
        this.pieTitle = params.name;
        this.nodeReviewPage.review_type = nameObject[params.name];
        this.getNodeReviewThroughs();
        this.dialogTableVisible = true;
      });
    },
    getNodeReviewThroughs() {
      getNodeReviewThrough(
        this.get_pid(),
        this.nodeReviewPage,
        this.bodyData
      ).then((res) => {
        this.gridData = res.data;
        this.nodeReviewPage_total = res.total;
        this.gridData.forEach((item) => {
          item.assignee = this.user_list[item.assignee]
            ? this.user_list[item.assignee].nickname
            : "";
        });
      });
    },
    getNodeReviewTimes() {
      getNodeReviewTime(this.get_pid(), this.bodyData).then((res) => {
        this.barData = [];
        this.checked_fileType.forEach((item) => {
          let flag = true;
          res.Data.filter((resItem) => {
            if (resItem.fileTypeId === item) {
              this.barData.push(
                (resItem.reviewSecond / (60 * 60 * 24)).toFixed(2)
              );
              flag = false;
            }
          });
          if (flag) {
            this.barData.push(0);
          }
        });
        this.bar_charts();
      });
    },
    // 柱形图
    bar_charts() {
      this.myChart_bar2 = this.$echarts.init(document.getElementById("bar2"));
      const option = {
        tooltip: {},
        title: {
          text: this.$t("addMyColla.chart.title4"),
          textStyle: {
            fontFamily: "Source Han Sans CN",
            color: "rgba(56, 56, 56, 1)",
          },
        },
        xAxis: {
          type: "category",
          data: this.checked_fileType.map((item) => {
            return this.fileType[item].name;
          }),
          axisLine: {
            lineStyle: {
              color: "rgba(235, 238, 245, 1)",
            },
          },
          nameTextStyle: {
            color: "rgba(128, 128, 128, 1)",
          },
          splitLine: {
            lineStyle: {
              color: "#EBEEF5",
            },
          },
          axisTick: {
            lineStyle: {
              color: "rgba(166, 166, 166, 1)",
            },
            alignWithLabel: true,
          },
          axisLabel: {
            color: "rgba(128, 128, 128, 1)",
            formatter: function (value) {
              let texts = value;
              if (texts.length > 10) {
                // 限制长度自设
                texts =
                  texts.substr(0, 10) + "\n" + texts.substr(10, value.length);
              }
              return texts;
            },
            interval: 0,
          },
          name: this.$t("addMyColla.chart.title5"),
        },
        yAxis: {
          type: "value",
          axisLine: {
            show: true,
            lineStyle: {
              color: "#EBEEF5",
            },
          },
          axisLabel: {
            color: "rgba(128, 128, 128, 1)",
          },
          nameTextStyle: {
            color: "rgba(128, 128, 128, 1)",
            fontSize: "14",
          },
          name: this.$t("addMyColla.chart.title6"),
        },
        grid: {
          left: "11%",
          right: "15%",
          top: "20%",
        },
        series: {
          type: "bar",
          barWidth: 42,
          data: this.barData,
          itemStyle: {
            color: "rgba(222, 238, 250, 1)",
          },
        },
        dataZoom: [
          {
            show: true,
            height: 5,
            xAxisIndex: [0],
            bottom: 5,
            showDetail: false,
            showDataShadow: false,
            borderColor: "transparent",
            textStyle: {
              fontSize: 0,
            },
            endValue: 5, //从0开始的相当于5个
            backgroundColor: "rgba(0,0,0,0)",
            borderWidth: 0,
            handleSize: "0%",
          },
        ],
      };
      this.myChart_bar2.setOption(option);
    },
  },
};
</script>

<style scoped lang="scss">
.user_select {
  .el-select-dropdown__item {
    display: flex;
    align-items: center;
    .avatar {
      display: flex;
    }
    .select_item {
      margin-left: 14px;
    }
  }
}
.title-relative {
  position: relative;
  margin-left: 20px;
  cursor: pointer;
  .point-absolute {
    position: absolute;
    width: 5px;
    height: 5px;
    left: -12px;
    top: -2px;
    border-radius: 50%;
    background-color: red;
  }
}
.menu {
  display: flex;
  gap: 40px;
  margin-bottom: 25px;

  .item {
    font-size: 18px;
    color: #777;
    cursor: pointer;
  }
  .svgClass {
    position: relative;
    right: 0.082rem;
    color: rgba(255, 255, 255, 0);
    width: 0.1042rem;
    height: 0.1042rem;
    cursor: pointer;
  }
}
.bottom-border {
  border-bottom: 2px solid rgba(48, 100, 143, 1);
}

.pingshenmenu {
  position: relative;
  .number-ab {
    position: absolute;
    top: -7px;
    right: -15px;
    background-color: red;
    border-radius: 50%;
    width: 15px;
    height: 15px;
    display: flex;
    font-size: 14px;
    align-items: center;
    color: #fff;
    justify-content: center;
  }
}

.pingshen-status-tag {
  padding: 4px 16px;
  max-width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  background-color: #fff;
  border-width: 1px;
}
.wrong {
  width: 24px;
  height: 24px;
  margin-left: 20px;
}
.right {
  width: 24px;
  height: 24px;
  margin-left: 20px;
}
.img-list {
  display: flex;

  .img-box {
    height: 36px;
    width: 36px;
    box-sizing: content-box;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    margin-right: -8px;
    img {
      height: 100%;
      border-radius: 50%;
    }
  }
}
.hidden-img {
  display: flex;
  gap: 4px;
  img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
  }
}
::v-deep.el-pagination.is-background .el-pager li:not(.disabled).active {
  background: #568cb8;
}
.echarts_main_wrap {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  overflow: hidden;
  & > div {
    width: 49%;
    margin-top: 50px;
    height: 450px;
    background: rgba(255, 255, 255, 1);
    border-radius: 8px;
    padding: 12px 0 0 20px;
    & > div {
      width: 100%;
      height: 430px;
    }
  }
  .bar1 {
    overflow: hidden;
    width: 48%;
    background: rgba(255, 255, 255, 1);
    border-radius: 8px;
    margin-top: 30px;
    padding: 15px;
  }
}
.el-dropdown-menu {
  //height: 250px;
  overflow: scroll;
  label {
    width: 100%;
  }
}
.select {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  overflow-x: hidden;
}
.checkbox {
  margin-left: 15px;
  margin-bottom: 10px;
  font-size: 10px;
}
.btn {
  width: 59px;
  height: 26px;
  line-height: 26px;
  font-size: 12px;
  text-align: center;
  border-radius: 4px;
  background-color: rgba(229, 229, 229, 1);
  border-color: rgba(229, 229, 229, 1);
  color: rgba(84, 85, 86, 1);
}
</style>
<style scoped>
.sortable-column-header {
  display: flex;
  align-items: center;
}
.caret-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.clickable-rows::before {
  height: 0px;
}
.clickable-rows {
  cursor: default;
  height: 75vh;
  overflow: auto;
  background: white;
}
::-webkit-scrollbar {
  width: 0 !important;
}
::-webkit-scrollbar {
  width: 0 !important;
  height: 0;
}
.operate_button {
  display: block;
  margin: 15px 20px;
  text-align: left;
  cursor: pointer;
  color: #656667;
}
.operate_button:hover {
  color: #46b4f4;
}
/deep/ .el-table__body-wrapper::-webkit-scrollbar {
  width: 6px;
}
/deep/ .el-table .el-table__cell.gutter {
  background: #f0f2f5 !important;
}
.email_to {
  color: #707070;
  padding-bottom: 10px;
  border-bottom: 3px solid rgba(48, 100, 143, 1);
  cursor: pointer;
}
.email_to_hover {
  color: #a6a6a6;
  border-bottom: 3px solid rgba(48, 100, 143, 0);
  cursor: pointer;
}
/**密码登录按钮样式 */
.pass_to {
  color: #a6a6a6;
  padding-bottom: 10px;
  border-bottom: 3px solid rgba(48, 100, 143, 0);
  cursor: pointer;
}
.pass_to_hover {
  color: #707070;
  border-bottom: 3px solid rgba(48, 100, 143, 1);
  cursor: pointer;
  padding-bottom: 10px;
}
.font_size {
  font-size: 18px;
  font-weight: 600;
}
.point {
  margin-left: 10px;
}
.hidden-img {
  display: flex;
}
</style>

<style scoped>
/deep/ .el-loading-mask {
  pointer-events: none;
}
</style>
